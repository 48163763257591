


























































































































































import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import EditLiebiaoMenu from '@/components/shared/EditLiebiaoMenu.vue';
import DeleteLiebiaoMenu from '@/components/shared/DeleteLiebiaoMenu.vue';
import { Authenticator, LiebiaoManager, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { Nullable, TagEvent, RequestID, LiebiaoResult, Liebiao } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage, TagRequest, TagManager, ComponentID, TagResult, LiebiaoRequest, LiebiaoListManager, LiebiaoEvent } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share, filter, map, debounceTime, tap, throttle } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription, Subject, from } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';
import { Route, RouteConfig, Location } from 'vue-router';

type EditMode = "new" | "edit";
type SLiebiao = Liebiao;
type LiebiaoCategory = {
  id: number;
  title: string;
}

@Component({
  components: {
    AsyncButton, TagChip, EditLiebiaoMenu, DeleteLiebiaoMenu
  }
})
export default class LiebiaoListComponent extends Vue implements LiebiaoListManager
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.TAGBOSS_INSTANCE)
  private tagboss!: TagBoss;
  
  @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
  private liebiao_manager!: LiebiaoManager;

  public id: ComponentID = generateComponentID();
  public search_text: string = "";
  public liebiao_request$: Subject<LiebiaoRequest> = new Subject<LiebiaoRequest>();
  public liebiao_results$: Subject<LiebiaoResult> = new Subject<LiebiaoResult>();
  public request_subscription: Nullable<Subscription> = null;
  public last_liebiao_request_id: number = 0;
  public min_loader_duration: number = 600;
  public loader_subscription: Nullable<Subscription> = null;
  public loading: boolean = false;
  public inited: boolean = false;
  public liebiaos: Array<SLiebiao> = new Array<SLiebiao>();
  public page: number = 1;
  public total: number = 0;
  public categories: Array<LiebiaoCategory> = [
    {id:1, title:"Recent"},
    {id:2, title:"Haven't studied recently"},
    {id:3, title:"Haven't studied in a while"},
    {id:4, title:"Last studied"},
    {id:5, title:"TOCFL"},
    {id:6, title:"Resident Evil 0"}
  ];
  public page_size: number = 10;
  public liebiao_panels: Array<number> = [];
  public liebiao: Nullable<Liebiao> = null;
  public mode: EditMode = "new";
  public dialog: boolean = false;
  public delete_menu: boolean = false;

  public getSelf(): LiebiaoListManager {
    return this;
  }

  deleteLiebiao(liebiao: Liebiao): Observable<LiebiaoEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestLiebiaos({
      request_id: request_id,
      page: this.page,
      page_size: this.page_size,
      details: true,
      delete: [liebiao.id]
    });
    return this.liebiao_results$.pipe(
      filter( (liebiao_result: LiebiaoResult) => {
        return liebiao_result.request_id == request_id;
      }),
      take(1),
      map( (liebiao_result: LiebiaoResult) => {
        return ({
          type: "deleted"
        } as LiebiaoEvent);
      })
    );
  }

  newLiebiao(liebiao_name: string, liebiao_description: string): Observable<LiebiaoEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestLiebiaos({
      request_id: request_id,
      page: this.page,
      page_size: this.page_size,
      details: true,
      new: {name: liebiao_name, description: liebiao_description}
    });
    return this.liebiao_results$.pipe(
      filter( (liebiao_result: LiebiaoResult) => {
        return liebiao_result.request_id == request_id;
      }),
      take(1),
      map( (liebiao_result: LiebiaoResult) => {
        return ({
          type: "added"
        } as LiebiaoEvent);
      })
    );
  }
  
  updateLiebiao(liebiao_id: number, liebiao_name: string, liebiao_description: string): Observable<LiebiaoEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestLiebiaos({
      request_id: request_id,
      page: this.page,
      page_size: this.page_size,
      details: true,
      edit: {
        id: liebiao_id,
        data: {name: liebiao_name, description: liebiao_description}
      }
    });
    return this.liebiao_results$.pipe(
      filter( (liebiao_result: LiebiaoResult) => {
        return liebiao_result.request_id == request_id;
      }),
      take(1),
      map( (liebiao_result: LiebiaoResult) => {
        return ({
          type: "updated"
        } as LiebiaoEvent);
      })
    );
  }

  @Watch('page')
  onPageChange(): void {
    this.requestLiebiaos({
      request_id: generateRequestID(),
      details: true,
      page: this.page,
      page_size: this.page_size
    });
  }

  @Watch('search_text')
  onSearchTextChange(search_text: string): void {
    const request_id: RequestID = generateRequestID(); 
    this.requestLiebiaos({
      request_id: request_id,
      details: true,
      page: this.page,
      page_size: this.page_size
    });
  }

  closeEditLiebiaoMenu(): void {
    this.liebiao = null;
    this.dialog = false;
  }

  openNewLiebiaoMenu(): void {
    this.liebiao = null;
    this.mode = "new";
    this.dialog = true;
  }

  openEditLiebiaoMenu(liebiao: Liebiao): void {
    this.liebiao = liebiao;
    this.mode = "edit";
    this.dialog = true;
  }

  openDeleteLiebiaoMenu(liebiao: Liebiao): void {
    console.log('deleting the liebiao yo!');
    this.liebiao = liebiao;
    this.delete_menu = true;
  }

  length(): number {
    return Math.ceil(this.total / this.page_size);
  }

  created(): void {
      //wait till we're logged and app is ready before loading tags
      const app_ready$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1));
      app_ready$.subscribe({
        next: (app_stage: AppStage) => {
          this.initTagQuery();
          this.requestLiebiaos({
            request_id: generateRequestID(),
            details: true,
            page: this.page,
            page_size: this.page_size
          });
        }
      });
  }

  @Emit()
  openLiebiao(liebiao: SLiebiao): SLiebiao {
    return liebiao;
  }

  requestLiebiaos(liebiao_request: LiebiaoRequest): void {
    if(this.search_text?.length > 0) {
      liebiao_request.search = this.search_text;
    }
    this.liebiao_request$.next(liebiao_request);
  }

  initTagQuery(): void {
    let query_debounced$: Observable<LiebiaoRequest> = this.liebiao_request$.pipe(
      debounceTime(600),
      tap((liebiao_request: LiebiaoRequest) => {
        //console.log('debounced')
    }));
    let query_throttled$: Observable<LiebiaoRequest> = this.liebiao_request$.pipe(
      throttle(() => query_debounced$),
      tap((liebiao_request: LiebiaoRequest) => {
        //console.log('throttled')
      })
    );

    /*
    HEY YOU IDIOT!
    TODO
    1) try to not store these streams in variables when you make the quiz component (we dont want to create opportunities for memory leaks)
    2) load the lists first, then query again for preview entries
    */
    let liebiao_request_dethrottled$: Observable<LiebiaoRequest> = merge(query_throttled$, query_debounced$).pipe(
      filter((liebiao_request: LiebiaoRequest) => {
        if(this.last_liebiao_request_id <= 0) {
          this.last_liebiao_request_id = liebiao_request.request_id;
          return true;
        }
        if(this.last_liebiao_request_id !== liebiao_request.request_id) {
          this.last_liebiao_request_id = liebiao_request.request_id;
          return true;
        }
        return false;
      })
    );

    this.request_subscription = liebiao_request_dethrottled$.subscribe({
      next: (liebiao_request: LiebiaoRequest) => {
        const get_liebiaos$ = this.liebiao_manager.requestLiebiaos(liebiao_request).pipe(take(1),share());
        const off_timer$: Observable<number> = timer(this.min_loader_duration);
        const showLoadingIndicator$ = combineLatest(
          // OFF in X seconds
          get_liebiaos$.pipe( mapTo(false)),
          // OFF once we receive a result
          off_timer$.pipe( mapTo(false) )
        )
        .pipe(
          mapTo(false),
          startWith(true),
          distinctUntilChanged(),
          take(2)
        );
        this.loader_subscription = showLoadingIndicator$.subscribe({
          next: (is_loading: boolean) => {
            //console.log(`loader: ${is_loading}`);
            this.loading = is_loading;
          },
          complete: () => {
            this.loader_subscription?.unsubscribe();
            this.loader_subscription = null;
          }}
        );
        const get_liebiaos_subscription: Subscription = get_liebiaos$.subscribe({
          next: (liebiao_result: LiebiaoResult) => {
            this.liebiao_panels = [];
            this.inited = true;
            this.liebiao_results$.next(liebiao_result);
            /* if(tag_results.tags.length <= 0) this.no_tags = true; */
            this.liebiaos = [];
            this.liebiaos.splice(this.liebiaos.length);
            liebiao_result.liebiaos.forEach((liebiao: Liebiao) => {
              this.liebiaos.push(liebiao);
            });
            this.total = liebiao_result.total;
            //this.page = liebiao_result.page;
          },
          complete: () => {
            get_liebiaos_subscription.unsubscribe();
          }
        });
      } 
    });
  }

  cleanUp(): void {
    if(this.loader_subscription) this.loader_subscription.unsubscribe();
    if(this.request_subscription) this.request_subscription.unsubscribe();
  }

  destroyed(): void {
    this.cleanUp();
  }

  unmounted(): void {
    this.cleanUp();
  }
}
