






































import { Component, Vue, Prop, Watch, Emit, PropSync } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter, LiebiaoManager } from '@/services';
import { AxiosError, AxiosResponse } from 'axios';
import { Nullable, TagManager, Tag, TagEvent, NewTagData, SimpleValidationResult, TagRequest, VueForm, Liebiao, LiebiaoListManager, LiebiaoRequest, LiebiaoEvent } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share, debounceTime, switchMap, catchError, tap } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription, Subject, of } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';
import { InputValidationRule } from 'vuetify';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';

type EditMode = "new" | "edit";
type SLiebiao = Liebiao;
type SLiebiaoListManager = LiebiaoListManager;

@Component({
  components: {
    AsyncButton, TagChip
  }
})
export default class EditLiebiaoMenu extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
  private liebiao_manager!: LiebiaoManager;

  public liebiao_name_rules: Array<InputValidationRule> = [
    (liebiao_name: string) => !!liebiao_name || 'Liebiao must have a name',
    (liebiao_name: string) => (liebiao_name && liebiao_name.length <= 500 || 'Liebiao name must be 500 characters or less'),
    (liebiao_name: string) => (liebiao_name && liebiao_name.trim().length >= 1) || 'Liebiao must have a name',
    (liebiao_name: string) => (liebiao_name && this.isLiebiaoUnique()) || this.liebiaoValidationMessage()
  ];

  @PropSync('dialog', {type: Boolean}) public dialog_synced!: boolean;
  public liebiao_name: string = "";
  public liebiao_description: string = "";
  @Prop({default: "new"}) mode!: EditMode;
  @Prop() liebiao!: SLiebiao;
  @Prop() owner!: SLiebiaoListManager;
  public pending: boolean = false;
  public liebiao_name_is_unique: boolean = true;
  public liebiao_form_is_valid: boolean = true;
  public liebiao_validation_requests$: Subject<LiebiaoRequest> = new Subject<LiebiaoRequest>();
  public liebiao_validation_message: string = "Invalid liebiao";
  public liebiao_form: Nullable<VueForm> = null;

  @Emit()
  openLiebiao(liebiao: SLiebiao): SLiebiao {
    return liebiao;
  }

  getEditLiebiaoForm(): Nullable<VueForm> {
    if(this.liebiao_form) {
      return this.liebiao_form;
    } else {
      if(this.$refs.liebiao_form as VueForm) {
        this.liebiao_form = (this.$refs.liebiao_form as VueForm);
        return this.liebiao_form;
      }
      //console.log('liebiao_form not found!');
      return null;
    }
  }

  validateLiebiaoEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditLiebiaoForm();
    if(_tag_form) {
      _tag_form.validate();
      return true;
    }
    return false;
  }
  
  resetLiebiaoEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditLiebiaoForm();
    if(_tag_form) {
      _tag_form.reset();
      return true;
    }
    return false;
  }
  
  resetValidationLiebiaoEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditLiebiaoForm();
    if(_tag_form) {
      _tag_form.resetValidation();
      return true;
    }
    return false;
  }

  liebiaoValidationMessage(): string {
    return this.liebiao_validation_message;
  }

  isLiebiaoUnique(): boolean {
    return this.liebiao_name_is_unique;
  }

  created(): void {
    this.liebiao_validation_requests$.pipe(
      tap( (liebiao_request: LiebiaoRequest) => {
        //console.log('tag_request');
        this.liebiao_name_is_unique = true;
      }),
      debounceTime(500),
      switchMap( (liebiao_request: LiebiaoRequest) => {
        return this.liebiao_manager.validateLiebiao(liebiao_request).pipe(
          catchError( (error: AxiosError) => {
            console.log(error.response?.data);
            const validation_result: SimpleValidationResult = {
              valid: false,
            };
            if(error.response?.data?.errors?.name) {
              this.liebiao_name_is_unique = false;
              this.liebiao_validation_message = error.response.data.errors.name[0];
              this.validateLiebiaoEditForm();
            } else {
              validation_result.error = error;
            }
            return of(validation_result);
          })
        );
      }),
    )
    .subscribe({
      next: (validation_result: SimpleValidationResult) => {
        this.validateLiebiaoEditForm();
      },
      error: (error: AxiosError) => {
        console.log('some error ocurred');
      }
    });
  }

  @Watch('dialog')
  modeChanged(new_mode: EditMode): void {
    if(this.mode == "edit" && this.liebiao) {
        this.liebiao_name = this.liebiao.name;
        this.liebiao_description = this.liebiao.description;
    } else {
      this.liebiao_name = "";
      this.liebiao_description = "";
    }
    this.pending = false;
    this.liebiao_name_is_unique = true;
    this.liebiao_form_is_valid = true;
    this.resetValidationLiebiaoEditForm();
  }

  @Watch('liebiao_name')
  tagNameChanged(new_tag_name: string): void {
    if(!this.liebiao_name || this.liebiao_name.length <= 0) return;
    const liebiao_request: LiebiaoRequest = {
      request_id: generateRequestID()
    };
    if(this.mode == "edit") {
      liebiao_request.edit = {
        id: this.liebiao.id,
        data: {
          name: this.liebiao_name,
          description: this.liebiao_description,
        }
      };
    } else {
      liebiao_request.new = {
        name: this.liebiao_name,
        description: this.liebiao_description,
      };
    }
    this.liebiao_validation_requests$.next(liebiao_request);
  }

  @Emit()
  onClose(): boolean {
    this.liebiao_name_is_unique = true;
    this.liebiao_form_is_valid = true;
    this.resetLiebiaoEditForm();
    return true;
  }

  onSave(): void {
    this.validateLiebiaoEditForm();
    this.$nextTick( () => {
      if(this.liebiao_form_is_valid) {
        this.pending = true;
        if(this.mode == "new") {
          this.owner.newLiebiao(this.liebiao_name, this.liebiao_description).subscribe({
            next: (liebiao_event: LiebiaoEvent) => {
              this.pending = false;
              this.dialog_synced = false;
              this.onClose();
            }
          });
        } else if(this.mode == "edit") {
          this.owner.updateLiebiao(this.liebiao.id, this.liebiao_name, this.liebiao_description).subscribe({
            next: (liebiao_event: LiebiaoEvent) => {
              this.pending = false;
              this.dialog_synced = false;
              this.onClose();
            }
          });
        }
      }
    });
  }

}
