






























import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { Nullable, Tag, TagManager, Liebiao, LiebiaoListManager, LiebiaoEvent } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage, TagEvent } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';

type SLiebiao = Liebiao;
type SLiebiaoListManager = LiebiaoListManager;

@Component({
  components: {
    AsyncButton, TagChip
  }
})
export default class DeleteLiebiaoMenu extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.TAGBOSS_INSTANCE)
  private tagboss!: TagBoss;

  @PropSync('dialog', {type: Boolean}) public dialog_synced!: boolean;
  public tag_name: string = "";
  @Prop() liebiao!: SLiebiao;
  @Prop() owner!: SLiebiaoListManager;
  public pending: boolean = false;
  public delete_vocab: boolean = false;

  onClose(): void {
    console.log('onclose');
    this.dialog_synced = false;
  }

  deleteLiebiao(): void {
    this.pending = true;
    const delete_liebiao_sub: Subscription = this.owner.deleteLiebiao(this.liebiao).subscribe({
      next: (liebiao_event: LiebiaoEvent) => {
          this.pending = false;
          this.dialog_synced = false;
          delete_liebiao_sub.unsubscribe();
        }
    });
  }
}
